import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NextLink from "next/link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  menuTitle: string;
  menuItems: {
    title: string;
    url: string;
  }[];
};

/**
 * プルダウンメニュー
 */
export const PulldownMenu: React.FC<Props> = (props) => {
  const { menuTitle, menuItems } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ color: "white", my: 2 }}
      >
        {menuTitle}
        <ExpandMoreIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item) => (
          <NextLink key={item.url} href={item.url} passHref>
            <MenuItem onClick={handleClose}>{item.title}</MenuItem>
          </NextLink>
        ))}
      </Menu>
    </div>
  );
};
