import { forwardRef, useState, useCallback, MouseEvent } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
  Container,
  IconButton,
  Button,
  ButtonProps,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import NextLink from "next/link";
import { AdminPageRole } from "ascure-report-types";
import { EDITABLE_ROLES } from "../const";
import { PulldownMenu } from "../atoms/PulldownMenu";

type NavButtonProps = ButtonProps;
const NavButton = forwardRef<HTMLButtonElement, NavButtonProps>(
  function NavButton({ sx, ...buttonProps }: NavButtonProps, ref) {
    return (
      <Button
        sx={{
          my: 2,
          color: "white",
          ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" }, // hover時に少し暗い色にする
          ...sx,
        }}
        {...buttonProps}
        ref={ref}
      >
        {buttonProps.children}
      </Button>
    );
  }
);

export type Props = {
  roles: AdminPageRole[];
  handleOnSignOut?: () => void;
};

export const NavigationBar = ({ handleOnSignOut, roles }: Props) => {
  const [menuButtonElement, setMenuButtonElement] = useState<
    HTMLButtonElement | undefined
  >();
  const openNavMenu = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      setMenuButtonElement(e.currentTarget);
    },
    [setMenuButtonElement]
  );
  const closeNavMenu = useCallback(() => {
    setMenuButtonElement(undefined);
  }, [setMenuButtonElement]);

  // congnito グループを参照（編集権限を持つ role かどうかのチェック）
  // MEMO: role がない場合、全アクセス可
  const isAccessible =
    roles.length > 0
      ? roles.some((role) => EDITABLE_ROLES.includes(role))
      : true;

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton sx={{ mr: 2 }} onClick={openNavMenu}>
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={menuButtonElement}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(menuButtonElement)}
                onClose={closeNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <NextLink href="/" passHref>
                  <MenuItem onClick={closeNavMenu}>
                    <ListItemText>月次レポート</ListItemText>
                  </MenuItem>
                </NextLink>
                <NextLink href="/daily_dashboards/list" passHref>
                  <MenuItem onClick={closeNavMenu}>
                    <ListItemText>日次ダッシュボード</ListItemText>
                  </MenuItem>
                </NextLink>
                <NextLink href="/customers" passHref>
                  <MenuItem onClick={closeNavMenu}>
                    <ListItemText>取引先管理</ListItemText>
                  </MenuItem>
                </NextLink>
                <Divider />
                <MenuItem onClick={handleOnSignOut}>サインアウト</MenuItem>
              </Menu>
            </Box>

            <Box sx={{ mr: 2 }}>
              <Typography noWrap component="h1" variant="h6">
                ascureReport 管理画面
              </Typography>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                gap: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <PulldownMenu
                menuTitle={"月次レポート"}
                menuItems={[
                  {
                    title: "ascure卒煙",
                    url: "/sc",
                  },
                  {
                    title: "ascureDr.受診勧奨",
                    url: "/medr",
                  },
                ]}
              />
              <NextLink href="/daily_dashboards/list" passHref>
                <NavButton>日次ダッシュボード</NavButton>
              </NextLink>
              {isAccessible ? (
                <>
                  <NextLink href="/data_import" passHref>
                    <NavButton>データインポート</NavButton>
                  </NextLink>
                  <NextLink href="/customers" passHref>
                    <NavButton>取引先管理</NavButton>
                  </NextLink>
                  <NextLink href="/logs" passHref>
                    <NavButton>ログ閲覧</NavButton>
                  </NextLink>
                </>
              ) : null}
            </Box>
            <Box
              sx={{
                flexGrow: 0,
                display: { xs: "none", md: "block" },
              }}
            >
              <NavButton onClick={handleOnSignOut}>サインアウト</NavButton>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
