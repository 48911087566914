import {
  MemberStatus,
  StatusCategory,
  ConsultationStatus,
  PreMember,
  PreMemberView,
  MemberView,
  Member,
  UserStatus,
  UserStatusView,
  ReportState,
  AdminPageRole,
  ConsultationRawStatus,
  ServiceCode,
  ServicePath,
} from "ascure-report-types";
import { StaticImageData } from "next/image";
import ascureDrMedrTitle from "../../../public/serviceIcon/ascureDrMedrTitle.png";

export const REPORT_STATES: ReadonlyArray<ReportState> = [
  "BEFORE_CHECKED",
  "STANDBY_FOR_AUTO_SENDING",
  "STANDBY_FOR_MANUAL_SENDING",
  "ISSUED",
  "DISCARDED",
] as const;

type Header<T> = {
  readonly [key in keyof T]: {
    readonly field: string;
    readonly headerName: string;
  };
};
export const USER_STATUS_LIST_HEADER: Header<UserStatus> = {
  courseId: { field: "courseId", headerName: "コースID" },
  gender: { field: "gender", headerName: "性別" },
  userEncouragementState: {
    field: "userEncouragementState",
    headerName: "参加勧奨状況",
  },
  membershipCode: { field: "membershipCode", headerName: "招待コード" },
  term: { field: "term", headerName: "契約年度" },
  customerId: { field: "customerId", headerName: "取引先ID" },
  customerName: { field: "customerName", headerName: "取引先名" },
  status: { field: "status", headerName: "初回面談実施/卒煙状況" },
  userName: { field: "userName", headerName: "氏名" },
  birthday: { field: "birthday", headerName: "生年月日" },
  mailAddress: {
    field: "mailAddress",
    headerName: "メールアドレス",
  },
  appRegisteredDate: { field: "appRegisteredDate", headerName: "アプリ登録日" },
  nextReservationDate: {
    field: "nextReservationDate",
    headerName: "次回の面談予約日",
  },
  prevConsultationIndex: {
    field: "prevConsultationIndex",
    headerName: "前回面談回数",
  },
  planName: { field: "planName", headerName: "プラン名" },
  paymentRegisteredDate: {
    field: "paymentRegisteredDate",
    headerName: "プログラム契約日",
  },
  paymentWithdrawnAt: {
    field: "paymentWithdrawnAt",
    headerName: "プログラム解約日",
  },
  consultationDate1: {
    field: "consultationDate1",
    headerName: "初回面談実施日",
  },
  consultationDate2: {
    field: "consultationDate2",
    headerName: "2回目面談実施日",
  },
  consultationDate3: {
    field: "consultationDate3",
    headerName: "3回目面談実施日",
  },
  consultationDate4: {
    field: "consultationDate4",
    headerName: "4回目面談実施日",
  },
  consultationDate5: {
    field: "consultationDate5",
    headerName: "5回目面談実施日",
  },
  consultationDate6: {
    field: "consultationDate6",
    headerName: "6回目面談実施日",
  },
  reservation1Date: {
    field: "reservation1Date",
    headerName: "初回面談予約日",
  },
  extraConsultations: {
    field: "extraConsultations",
    headerName: "追加フォローアップ面談実施日",
  },
  consultationStatus1: {
    field: "consultationStatus1",
    headerName: "初回面談時の禁煙成否",
  },
  consultationStatus2: {
    field: "consultationStatus2",
    headerName: "2回目面談時の禁煙成否",
  },
  consultationStatus3: {
    field: "consultationStatus3",
    headerName: "3回目面談時の禁煙成否",
  },
  consultationStatus4: {
    field: "consultationStatus4",
    headerName: "4回目面談時の禁煙成否",
  },
  consultationStatus5: {
    field: "consultationStatus5",
    headerName: "5回目面談時の禁煙成否",
  },
  consultationStatus6: {
    field: "consultationStatus6",
    headerName: "プログラム最終卒煙成否",
  },
  consultationRawStatus6: {
    field: "consultationRawStatus6",
    headerName: "禁煙成否（自己申告含む）",
  },
  medicineName: { field: "medicineName", headerName: "医薬品名" },
  howToCheckSmokingCessationResult: {
    field: "howToCheckSmokingCessationResult",
    headerName: "禁煙結果の確認方法",
  },
  reasonForFailure: {
    field: "reasonForFailure",
    headerName: "禁煙失敗理由",
  },
  // ↓OptionItems
  companyName: { field: "companyName", headerName: "会社名" },
  couponCode: { field: "couponCode", headerName: "クーポンコード" },
  phoneNumber: { field: "phoneNumber", headerName: "電話番号" },
  officeName: { field: "officeName", headerName: "事業所名" },
  branchName: { field: "branchName", headerName: "支店名" },
  insuredPersonNumber: {
    field: "insuredPersonNumber",
    headerName: "被保険者証番号",
  },
  insuredPersonSymbol: {
    field: "insuredPersonSymbol",
    headerName: "被保険者証記号",
  },
  insurerNumber: { field: "insurerNumber", headerName: "保険者番号" },
  employeeNumber: { field: "employeeNumber", headerName: "社員番号" },
  workPlaceAddress: { field: "workPlaceAddress", headerName: "勤務先の住所" },
  isDependentFamilyMember: {
    field: "isDependentFamilyMember",
    headerName: "扶養家族かどうか",
  },
  userAddress: { field: "userAddress", headerName: "利用者の住所" },
  introducerName: { field: "introducerName", headerName: "紹介者の氏名" },
  introducerEmailAddress: {
    field: "introducerEmailAddress",
    headerName: "紹介者のメールアドレス",
  },
  inflowRoute: { field: "inflowRoute", headerName: "利用者の流入経路" },
  universalItem1: { field: "universalItem1", headerName: "汎用項目1" },
  universalItem2: { field: "universalItem2", headerName: "汎用項目2" },
  universalItem3: { field: "universalItem3", headerName: "汎用項目3" },
  universalItem4: { field: "universalItem4", headerName: "汎用項目4" },
  universalItem5: { field: "universalItem5", headerName: "汎用項目5" },
  universalItem6: { field: "universalItem6", headerName: "汎用項目6" },
  universalItem7: { field: "universalItem7", headerName: "汎用項目7" },
  universalItem8: { field: "universalItem8", headerName: "汎用項目8" },
};

export const MEMBER_REPORT_HEADER: Header<Member> = {
  ...USER_STATUS_LIST_HEADER,
  status: { field: "status", headerName: "卒煙状況" },
  statusCategory: {
    field: "statusCategory",
    headerName: "プログラム実施状況",
  },
  userEncouragementState: {
    field: "userEncouragementState",
    headerName: "参加勧奨状況",
  },
};

export const PRE_MEMBER_REPORT_HEADER: Header<PreMember> = {
  ...USER_STATUS_LIST_HEADER,
  status: { field: "status", headerName: "初回面談実施状況" },
  firstConsultationReservedDate: {
    field: "firstConsultationReservedDate",
    headerName: "初回面談予約日",
  },
  firstConsultationConductedDate: {
    field: "firstConsultationConductedDate",
    headerName: "初回面談実施日",
  },
};

export const INVOICE_DETAIL_HEADER = {
  num: { field: "num", headerName: "No" },
  publishMonth: { field: "publishMonth", headerName: "請求月" },
  serviceDescription: { field: "serviceDescription", headerName: "品番・品目" },
  userName: { field: "userName", headerName: "氏名" },
  createdDate: { field: "createdDate", headerName: "日付" },
  amount: { field: "amount", headerName: "個数" },
  unitPrice: { field: "unitPrice", headerName: "単価" },
  price: { field: "price", headerName: "金額" },
};

export const CUSTOMER_STAFF_DETAIL_HEADER = {
  name: { field: "name", headerName: "氏名" },
  switchableCustomerIds: {
    field: "switchableCustomerIds",
    headerName: "所属取引先",
  },
};

export const MEMBER_STATUS_LABEL: { [key in MemberStatus]: string } = {
  BEFORE_CONSULTATION: "初回面談前",
  BEFORE_CONCLUSION: "初回面談後契約前",
  NO_FIRST_CONSULTATION: "初回面談予約なし",
  REJECTED: "サービス対象外",
  NOT_YET: "禁煙開始前",
  SUCCEEDING: "成功中",
  FAILING: "失敗中",
  NOT_REACHED: "確認できず",
  SUCCEED: "卒煙成功",
  FAILED: "卒煙失敗",
  ABORTED: "途中離脱",
  WITHDRAWN: "解約",
  INVALID: "Error",
  HIDDEN: "非表示",
} as const;

export const MEMBER_STATUS_CATEGORY_LABEL: {
  [key in StatusCategory]: string;
} = {
  PRE_MEMBER: "契約前",
  PROGRESS: "実施中",
  FINISHED: "終了",
  INVALID: "Error/非表示",
} as const;

export const CONSULTAION_LABEL: { [key in ConsultationStatus]: string } = {
  NOT_RESERVED: "予約なし(非表示)",
  RESERVED: "予約あり(非表示)",
  SKIPPED: "スキップ",
  NON_SMOKED: "成功",
  SMOKED: "失敗",
  BEFORE_PROGRAM: "禁煙開始前",
  UNKNOWN: "不明",
} as const;

export const CONSULTAION_RAW_LABEL: { [key in ConsultationRawStatus]: string } =
  {
    NON_SMOKED: "成功",
    SMOKED: "失敗",
    UNKNOWN: "不明",
  } as const;

/**
 * 禁煙結果の確認方法
 */
export const HOW_TO_CHECK_SMOKING_CESSATION_RESULTS = {
  CotinineTestAtTheTimeOfInterview: "面談時のコチニン検査",
  CotinineTestOtherThanAtTheTimeOfInterview: "面談時以外のコチニン検査",
  NoCotinineTest: "コチニン検査なし",
  other: "その他",
} as const;
export type HowToCheckSmokingCessationResult =
  keyof typeof HOW_TO_CHECK_SMOKING_CESSATION_RESULTS;

export const HOW_TO_CHECK_SMOKING_CESSATION_RESULTS_ITEMS = [
  ...(
    Object.keys(
      HOW_TO_CHECK_SMOKING_CESSATION_RESULTS
    ) as HowToCheckSmokingCessationResult[]
  ).map((key) => ({
    value: HOW_TO_CHECK_SMOKING_CESSATION_RESULTS[key],
    label: HOW_TO_CHECK_SMOKING_CESSATION_RESULTS[key],
  })),
  {
    value: "",
    label: "（空欄）",
  },
];

/**
 * 禁煙失敗理由
 */
export const REASON_FOR_FAILURES = {
  SmokingSituationDoesNotChange: "喫煙状況変わらず",
  SmokeReductionIsComplete: "減煙は出来た",
  PossibleDrugEffects: "医薬品影響の可能性あり",
  PossibleSidestreamSmoke: "副流煙の可能性あり",
} as const;
export type ReasonForFailure = keyof typeof REASON_FOR_FAILURES;

export const REASON_FOR_FAILURE_ITEMS = [
  ...(Object.keys(REASON_FOR_FAILURES) as ReasonForFailure[]).map((key) => ({
    value: REASON_FOR_FAILURES[key],
    label: REASON_FOR_FAILURES[key],
  })),
  {
    value: "",
    label: "（空欄）",
  },
];

export const convertPreMemberStatusToLabel = (
  preMember: PreMember
): PreMemberView => ({
  ...preMember,
  status: MEMBER_STATUS_LABEL[preMember.status],
});

export const convertMemberStatusToLabel = (member: Member): MemberView => {
  return {
    ...member,
    status: MEMBER_STATUS_LABEL[member.status],
    statusCategory: MEMBER_STATUS_CATEGORY_LABEL[member.statusCategory],
    consultationStatus1: CONSULTAION_LABEL[member.consultationStatus1],
    consultationStatus2: CONSULTAION_LABEL[member.consultationStatus2],
    consultationStatus3: CONSULTAION_LABEL[member.consultationStatus3],
    consultationStatus4: CONSULTAION_LABEL[member.consultationStatus4],
    consultationStatus5: CONSULTAION_LABEL[member.consultationStatus5],
    consultationStatus6: CONSULTAION_LABEL[member.consultationStatus6],
    consultationRawStatus6: CONSULTAION_LABEL[member.consultationRawStatus6],
  };
};

export const convertUserStatusToView = (
  userStatus: UserStatus
): UserStatusView => ({
  ...userStatus,
  status: MEMBER_STATUS_LABEL[userStatus.status],
  consultationStatus1: CONSULTAION_LABEL[userStatus.consultationStatus1],
  consultationStatus2: CONSULTAION_LABEL[userStatus.consultationStatus2],
  consultationStatus3: CONSULTAION_LABEL[userStatus.consultationStatus3],
  consultationStatus4: CONSULTAION_LABEL[userStatus.consultationStatus4],
  consultationStatus5: CONSULTAION_LABEL[userStatus.consultationStatus5],
  consultationStatus6: CONSULTAION_LABEL[userStatus.consultationStatus6],
  consultationRawStatus6: CONSULTAION_LABEL[userStatus.consultationRawStatus6],
});

export const CONSULTATION_MENU_ITEMS = (
  Object.keys(CONSULTAION_LABEL) as ConsultationStatus[]
).map((key) => ({
  value: key,
  label: CONSULTAION_LABEL[key],
}));
export const CONSULTATION_RAW_MENU_ITEMS = [
  ...(Object.keys(CONSULTAION_RAW_LABEL) as ConsultationRawStatus[]).map(
    (key) => ({
      value: key,
      label: CONSULTAION_RAW_LABEL[key],
    })
  ),
  {
    value: "",
    label: "（空欄）",
  },
];

export const MEMBER_STATUS_MENU_ITEMS = (
  Object.keys(MEMBER_STATUS_LABEL) as MemberStatus[]
).map((key) => ({
  value: key,
  label: MEMBER_STATUS_LABEL[key],
}));

export const PREV_CONSULTATION_INDEX_MENU_ITEMS = [
  {
    value: -1,
    label: "なし",
  },
  {
    value: 0,
    label: "1回目",
  },
  {
    value: 1,
    label: "2回目",
  },
  {
    value: 2,
    label: "3回目",
  },
  {
    value: 3,
    label: "4回目",
  },
  {
    value: 4,
    label: "5回目",
  },
  {
    value: 5,
    label: "最終",
  },
];

export type ReportStatusLabel = {
  value: ReportState;
  label: string;
  color:
    | "inherit"
    | "warning"
    | "success"
    | "info"
    | "primary"
    | "secondary"
    | "error";
};

export const REPORT_STATUS_LABEL: { [key in ReportState]: string } = {
  BEFORE_CHECKED: "チェック前",
  STANDBY_FOR_AUTO_SENDING: "送付予約",
  STANDBY_FOR_MANUAL_SENDING: "手動送付待ち",
  ISSUED: "発行する",
  DISCARDED: "破棄する",
} as const;

/**
 * レポートステータスラベル
 * MEMO: ステータス一括変更用
 */
export const REPORT_STATUS_LABELS: {
  [key in ReportState]: ReportStatusLabel[];
} = {
  // チェック前
  BEFORE_CHECKED: [
    {
      value: "STANDBY_FOR_AUTO_SENDING",
      label: "送付予約",
      color: "warning",
    },
    {
      value: "STANDBY_FOR_MANUAL_SENDING",
      label: "手動送付待ち",
      color: "warning",
    },
    {
      value: "DISCARDED",
      label: "破棄する",
      color: "inherit",
    },
  ],
  // 送付予約
  STANDBY_FOR_AUTO_SENDING: [
    {
      value: "STANDBY_FOR_MANUAL_SENDING",
      label: "手動送付待ち",
      color: "warning",
    },
    // MEMO: レポート発行は一括でできない仕様とする
    // {
    //   value: "ISSUED",
    //   label: "発行する",
    //   color: "success",
    // },
    {
      value: "DISCARDED",
      label: "破棄する",
      color: "inherit",
    },
  ],
  // 手動送付待ち
  STANDBY_FOR_MANUAL_SENDING: [
    {
      value: "STANDBY_FOR_AUTO_SENDING",
      label: "送付予約",
      color: "warning",
    },
    // MEMO: レポート発行は一括でできない仕様とする
    // {
    //   value: "ISSUED",
    //   label: "発行する",
    //   color: "success",
    // },
    {
      value: "DISCARDED",
      label: "破棄する",
      color: "inherit",
    },
  ],
  // 発行する
  ISSUED: [
    {
      value: "DISCARDED",
      label: "破棄する",
      color: "inherit",
    },
  ],
  // 破棄する
  DISCARDED: [
    {
      value: "BEFORE_CHECKED",
      label: "破棄を取り消す",
      color: "inherit",
    },
  ],
};

/**
 * 編集権限を持つ roles
 */
export const EDITABLE_ROLES: AdminPageRole[] = ["admin", "editor"];

/**
 * サービス名
 */
export type SERVICE_NAME = "ascureDr.受診勧奨";
export const SERVICE_CODE_LIST: Record<SERVICE_NAME, ServiceCode> = {
  "ascureDr.受診勧奨": "MedR",
};
export const SERVICE_PATH_LIST: Record<SERVICE_NAME, ServicePath> = {
  "ascureDr.受診勧奨": "medr",
};
/**
 * サービス名とアイコンの対応表
 */
export const SERVICE_ICON_LIST: Record<SERVICE_NAME, StaticImageData> = {
  "ascureDr.受診勧奨": ascureDrMedrTitle,
};
