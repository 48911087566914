import "../styles/globals.css";
import type { AppProps } from "next/app";
import { Amplify } from "aws-amplify";
import { Authenticator, View, useTheme, Text } from "@aws-amplify/ui-react";
import Head from "next/head";
import "@aws-amplify/ui-react/styles.css";
import { NavigationBar } from "../components/organisms/NavigationBar";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AdminPageRole } from "ascure-report-types";

// MUI X Pro のライセンスキーを設定する(コンポーネントのrender前に実行する必要あり)
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_PRO_LICENSE_KEY || "");

// 既存のcognitoの設定
const config = {
  aws_cognito_region: "ap-northeast-1",
  aws_project_region: "ap-northeast-1",
  aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
  ssr: true,
};
Amplify.configure(config);

// Authenticator内のみでamplify-uiを利用する
const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text>ascureReport 管理画面</Text>
      </View>
    );
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text>パスワードをリセットします</Text>
        </View>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text>パスワードをリセットします</Text>
        </View>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      return <></>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      label: "email",
      placeholder: "Enter your email:",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter your password:",
    },
  },
  forceNewPassword: {
    password: {
      labelHidden: false,
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      label: "email",
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: "Enter your Confirmation Code:",
      label: "リセットコード",
      isRequired: true,
    },
    password: {
      labelHidden: false,
      label: "新しいパスワード",
      isRequired: true,
    },
    confirm_password: {
      labelHidden: false,
      label: "新しいパスワード(確認)",
      isRequired: true,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      label: "アプリから6桁の認証番号を入力してください",
      placeholder: "Enter your Confirmation Code:",
      isRequired: true,
    },
  },
};

export default function App({
  Component,
  pageProps,
}: AppProps<{ cognitoUsername: string }>) {
  return (
    <>
      <Head>
        <title>ascureReport</title>
        <meta name="description" content="ascureReport 管理画面" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        <Authenticator
          hideSignUp
          formFields={formFields}
          components={components}
        >
          {({ signOut, user }) => {
            // MEMO: ログアウトしないと、セッションが更新されない、role も更新されない
            const roles =
              (user?.getSignInUserSession()?.getIdToken().payload[
                "cognito:groups"
              ] as AdminPageRole[]) || [];

            return (
              <>
                <NavigationBar roles={roles} handleOnSignOut={signOut} />
                <ErrorBoundary>
                  <Component
                    {...pageProps}
                    cognitoUsername={user?.username ?? ""}
                  />
                </ErrorBoundary>
              </>
            );
          }}
        </Authenticator>
      </main>
    </>
  );
}
