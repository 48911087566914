import { Box, Card, CardContent, Typography } from "@mui/material";

import WarningIcon from "@mui/icons-material/WarningOutlined";

export default function ErrorPanel() {
  return (
    <Box
      sx={{
        height: "50vh",
        minHeight: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <WarningIcon color="warning" fontSize="large" />
            <Typography fontSize="1.6em" component="h2">
              エラーが発生しました
            </Typography>
          </Box>

          <Typography component="p">
            お手数をおかけして申し訳ありません。以下をお試しください。
          </Typography>
          <ul style={{ margin: "0" }}>
            <li>画面を再読み込みする</li>
            <li>サインアウト後、再度ログインする</li>
          </ul>
        </CardContent>
      </Card>
    </Box>
  );
}
